@mixin dark-theme {
  .provider-register__upload {
    background-color: #343434;

    svg {
      color: #cbcbcb;
    }
  }
  .settings-menu-entry svg {
    border: 1px solid #cbcbcb;
  }
}

@mixin light-theme {
  .provider-register__upload {
    background-color: #cbcbcb;

    svg {
      color: #212121;
    }
  }
  .settings-menu-entry svg {
    border: 1px solid #212121;
  }
}

@media (prefers-color-scheme: light) {
  @include light-theme();
}

@media (prefers-color-scheme: dark) {
  @include dark-theme();
}

.light-theme {
  @include light-theme();
}

.dark-theme {
  @include dark-theme();
}

/* LOGIN PAGE */
.login-page {
  .box-component {
    margin-bottom: 35px;
  }

  &__register-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__register-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button-component {
      width: 45% !important;
    }

    &.single-button {
      .button-component {
        width: 100% !important;
      }
    }
  }

  .google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      height: 1.5rem;
      margin: 0 5px;
      fill: white;
    }
  }
}

.button-component.wide-button {
  button {
    width: 100%;
    max-width: 400px;
  }
}

.provider-register {
  &__text {
    font-size: 22px;
  }

  &__upload {
    cursor: pointer;
    width: 90%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    border-radius: 15px;

    svg {
      width: 80px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

/* STEMPLER DASHBOARD */
.stempler-dashboard {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    .vertical-carousel,
    .card-grid {
      flex-grow: 1;
    }

    .no-stempl {
      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: larger;
        }

        .box-component {
          width: 75%;
        }
      }

      &__arrow {
        align-self: flex-end;
        margin-right: 15%;
        margin-top: 15px;
      }
    }
  }

  &__header {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    .stempl-view-icon {
      color: #cbcbcb;
      margin-left: 5px;
      transition: all 250ms ease-in-out;

      &.active {
        color: #ffce00;
      }
    }
  }
}

.scan-view {
  &__title {
    font-size: larger;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.qr-display {
  background-color: #fff;
  font-family: "roc-grotesk", Helvetica, Arial, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 50px;
  box-sizing: border-box;
  font-size: 22px;
  max-width: 1500px;
  margin: auto;

  @media print {
    max-width: none;
    width: 100%;
    height: 100%;

    .button-component {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  &--info {
    margin-bottom: 30px;

    &--logo {
      float: left;
      height: 60px;
      overflow: hidden;

      svg {
        margin-top: -20px;
        width: 100px;
        height: 100px;
      }
    }

    p {
      margin: 0;
    }
  }

  &--heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    flex-wrap: wrap;

    div {
      word-break: break-word;
    }
  }

  &--button-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &--qr-codes {
    display: flex;
    flex-wrap: wrap;
  }

  &--qr-code-wrapper {
    min-width: 300px;
    box-sizing: border-box;
    padding: 15px;
    flex-grow: 1;

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.feedback-page {
  &__wrapper {
    .box-component__content {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__smiley-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 25px auto;
    width: 100%;

    .active {
      color: #ffce00;
    }

    svg {
      height: 3rem;
      width: auto;
      transition: color 150ms ease-in-out;
    }
  }
}

/* SETTINGS */
.settings-menu-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    flex-shrink: 0;
    width: 27px;
    height: 27px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 3px;
    margin: 10px 38px 10px 28px;
  }
  &.heading {
    svg {
      margin: 10px 10px 10px 3px;
    }
  }
}

.deletion-text {
  font-size: 12px;
  margin: 0 auto;
  color: #eb576d;
  text-align: center;
}
.card-settings-entry {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
  align-items: center;
  &--label {
    font-weight: bolder;
    width: 35%;
    word-break: break-all;
    padding-right: 5%;
  }
  &--value {
    width: 60%;
    word-break: break-all;
    svg,
    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }

    &--color {
      width: 27px;
      height: 27px;
      border-radius: 5px;
      &.lightgreen {
        background-color: #a2be46;
        border: 1px solid darken(#a2be46, 15%);
      }
      &.green {
        background-color: #71b87c;
        border: 1px solid darken(#71b87c, 15%);
      }
      &.blue {
        background-color: #4faddb;
        border: 1px solid darken(#4faddb, 15%);
      }
      &.orange {
        background-color: #eda93d;
        border: 1px solid darken(#eda93d, 15%);
      }
      &.red {
        background-color: #eb576d;
        border: 1px solid darken(#eb576d, 15%);
      }
      &.darkgrey {
        background-color: #121212;
        border: 1px solid darken(#121212, 15%);
      }
      &.purple {
        background-color: #c292de;
        border: 1px solid darken(#c292de, 15%);
      }
      &.yellow {
        background-color: #ffce00;
        border: 1px solid darken(#ffce00, 15%);
      }
      &.grey {
        background-color: #ebebeb;
        border: 1px solid darken(#ebebeb, 15%);
      }
    }
  }
}
