@mixin dark-theme {
  .red-text {
    color: #eb576d;
  }

  .header-icon {
    color: white;
  }

  .logo-on-color {
    .stamp {
      fill: #212121;
    }

    .stamper {
      fill: white;
    }
  }
}

@mixin light-theme {
  .red-text {
    color: #eb576d;
  }

  .header-icon {
    color: black;
  }

  .logo-on-color {
    .stamper {
      fill: #212121;
    }

    .stamp {
      fill: white;
    }
  }
}

@media (prefers-color-scheme: light) {
  @include light-theme();
}

@media (prefers-color-scheme: dark) {
  @include dark-theme();
}

.light-theme {
  @include light-theme();
}

.dark-theme {
  @include dark-theme();
}

.centered-text {
  text-align: center;
  margin: 15px auto;
}

svg {
  width: inherit;
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}

.register {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.register__google {
  background-color: #4285f4;
}

.register div {
  margin-top: 7px;
}

.reset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.reset div {
  margin-top: 7px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.header-icon {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.logout-icon {
  margin-left: 6px;
  box-sizing: border-box;
  padding: 1px;
}

.stempl-image {
  max-width: 50px;
  max-height: 50px;
}

h1 {
  font-size: 30px;
  font-weight: bolder;
  margin: 10px 0;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 3px;
  width: 100%;
  display: block;
  margin: 15px auto;
  background-color: #ebebeb;
}

.transition-hidden {
  transition: all 0.8s ease-in-out;
  opacity: 0;
  overflow: hidden;
  max-height: 0;
}

.transition-display {
  opacity: 1;
  max-height: 1000px;
}

.spacer {
  height: 30px;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.login-form {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  &--password {
    position: relative;

    &--icon {
      position: absolute;
      top: 13px;
      right: 0;
    }
  }

  &__forgot-password {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 20px;
    color: gray;
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.version-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  font-size: 10px;
}